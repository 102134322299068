import React from "react";
import { CLink } from "@coreui/react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { parseSortingObject } from "../../../common/utils/dataTable";
import { dateFormat } from "../../../common/utils/date";
import { testIdWrapper } from "../../../common/utils/testing";
import { RefClient } from "../../types/ref-client";
import "./index.scss";

export type TableParams = {
  page?: number;
  order?: string;
};

export type RefClientTableProps = {
  data: RefClient[];
  page: number;
  perPage: number;
  count: number;
  loading?: boolean;
  onParameterChange?: (params: TableParams) => void;
};

const renderRefUserColumn = (client: RefClient) => {
  const testId = `client-agents-count-${client.id}`;
  return (
    <CLink
      to={`/iam/ref-users?client=${client.id},${client.name}`}
      className="btn-link"
      data-testid={testId}
    >
      {client.agentCount || 0}
    </CLink>
  );
};

const renderBdmColumn = (client: RefClient) => {
  return testIdWrapper(
    client.bdm ? (
      <div>{`${client.bdm.firstName} ${client.bdm.lastName}`}</div>
    ) : (
      ""
    ),
    `client-bdm-${client.id}`
  );
};

const renderCreatedColumn = (client: RefClient) => {
  let createdAt = "";
  if (client.createdAt) {
    const submittedAt = new Date(client.createdAt);
    createdAt = dateFormat(submittedAt, "dd/MM/yyyy - hh:mm");
  }

  return testIdWrapper(createdAt, `created-at-${client.id}`);
};

const renderActionColumn = (client: RefClient) => (
  <CLink
    to={`/iam/ref-clients/${client.id}/admin`}
    className="btn btn-link"
    data-testid={`client-admin-${client.id}`}
  >
    Admin
  </CLink>
);

const RefClientTable: React.FC<RefClientTableProps> = (
  props: RefClientTableProps
) => {
  const columns: IDataTableColumn<RefClient>[] = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      width: "80px",
      cell: ({ id }) => testIdWrapper(id, `client-id-${id}`),
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: ({ id, name }) => testIdWrapper(name, `client-name-${id}`),
    },
    {
      name: "Salesperson",
      selector: "users",
      sortable: false,
      cell: (item) => renderRefUserColumn(item),
    },
    {
      name: "Broker BDM",
      selector: "bdm",
      sortable: false,
      cell: (item) => renderBdmColumn(item),
    },
    {
      name: "Created",
      selector: "createdAt",
      cell: (item) => renderCreatedColumn(item),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (item) => renderActionColumn(item),
      sortable: false,
      width: "80px",
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={props.data}
      progressPending={props.loading}
      paginationTotalRows={props.count}
      paginationPerPage={props.perPage}
      paginationComponentOptions={{ noRowsPerPage: true }}
      paginationDefaultPage={props.page}
      onSort={(column, direction) => {
        const order = parseSortingObject({
          column: column.selector as string,
          direction,
        });
        props.onParameterChange?.({
          page: undefined,
          order,
        });
      }}
      onChangePage={(page) => {
        props.onParameterChange?.({
          page,
        });
      }}
      className="ref-client-table"
      noHeader
      responsive
      sortServer
      paginationServer
      pagination
    />
  );
};

export default RefClientTable;
