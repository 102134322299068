import React from "react";
import { CLink } from "@coreui/react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { parseSortingObject } from "../../../common/utils/dataTable";
import { dateFormat } from "../../../common/utils/date";
import { testIdWrapper } from "../../../common/utils/testing";
import { RefUser } from "../../types/ref-user";
import "./index.scss";

export type TableParams = {
  page?: number;
  order?: string;
};

const renderNameColumn = (user: RefUser) => {
  const fullName = `${user.firstName} ${user.lastName}`;
  return <div>{testIdWrapper(fullName, `user-name-${user.id}`)}</div>;
};

const renderLastLoginColumn = (user: RefUser) => {
  return testIdWrapper(
    user.lastLoginAt
      ? dateFormat(new Date(user.lastLoginAt), "dd/MM/yyyy - hh:mm")
      : "-",
    `last-login-${user.id}`
  );
};

const renderActionColumn = (user: RefUser) => (
  <div>
    <CLink
      to={`/iam/ref-users/${user.id}/admin`}
      className="btn btn-link"
      data-testid={`edit-link-${user.id}`}
    >
      Admin
    </CLink>
  </div>
);

export type RefUserTableProps = {
  data: RefUser[];
  page: number;
  perPage: number;
  count: number;
  loading?: boolean;
  showCompanyColumn?: boolean;
  onParameterChange?: (params: TableParams) => void;
};

const RefUserTable: React.FC<RefUserTableProps> = (
  props: RefUserTableProps
) => {
  const { showCompanyColumn = true } = props;
  const columns: IDataTableColumn<RefUser>[] = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      width: "80px",
      cell: ({ id }) => testIdWrapper(id, `user-id-${id}`),
    },
    {
      name: "Name",
      selector: "firstName",
      cell: (item) => renderNameColumn(item),
      sortable: true,
    },
    {
      name: "Company",
      selector: "client.name",
      sortable: false,
      cell: ({ id, client }) => testIdWrapper(client.name, `client-name-${id}`),
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      cell: ({ id, email }) => testIdWrapper(email, `user-email-${id}`),
    },
    {
      name: "Mobile",
      selector: "mobile",
      sortable: true,
      cell: ({ id, mobile }) => testIdWrapper(mobile, `user-mobile-${id}`),
    },
    {
      name: "Last login",
      cell: (item) => renderLastLoginColumn(item),
    },
    {
      name: "Actions",
      cell: (item) => renderActionColumn(item),
      sortable: false,
      width: "80px",
    },
  ];

  if (!showCompanyColumn) {
    columns.splice(2, 1);
  }

  return (
    <DataTable
      columns={columns}
      data={props.data}
      progressPending={props.loading}
      paginationTotalRows={props.count}
      paginationPerPage={props.perPage}
      paginationComponentOptions={{ noRowsPerPage: true }}
      paginationDefaultPage={props.page}
      onSort={(column, direction) => {
        const order = parseSortingObject({
          column: column.selector as string,
          direction,
        });
        props.onParameterChange?.({
          page: undefined,
          order,
        });
      }}
      onChangePage={(page) => {
        props.onParameterChange?.({
          page,
        });
      }}
      className="ref-user-table"
      noHeader
      responsive
      sortServer
      paginationServer
      pagination
    />
  );
};

export default RefUserTable;
